import React, { Fragment } from "react";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Fragment>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist...</p>
    <p>
      Please continue on the <Link to="/">landing page</Link>
    </p>
  </Fragment>
);

export default NotFoundPage;
